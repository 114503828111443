/**
 * Click even handler for notices close button
 * sets 'subscription-notice' cookie to 'ack' when closed
 * allows us to track that the user conscientiously acknowledged the subscription notice by closing it
 */
function closeNoticeHandler() {
  let key = 'subscription-notice';
  let cookieValue = getCookie(key);

  if(!cookieValue) {
    // set cookie with default duration (removed when browser is closed)
    document.cookie = `${key}=ack`;
  }
}


/**
 * Get a cookie's value
 * Cookies are stored as a semi-colon-delimited string. There is no mechanism in JS to fetch a single cookie; You
 * have split the cookie string and loop through values looking for a particular cookie name
 * if cookie is not found, returns null
 * @param cookieName
 * @returns {string|null}
 */
function getCookie(cookieName) {
  let cookies = document.cookie.split('; ');
  let cookieData = null;
  for(let i = 0; i < cookies.length; i++) {
    cookieData = cookies[i].split('=')
    if(cookieName === cookieData[0]) {
      return cookies[1];
    }
  }

  return null;
}

/**
 * Remove notice after specified seconds.
 * @param seconds
 */
function removeNotice(seconds) {
  seconds = seconds * 1000; // in milliseconds

  let noticeDiv = document.querySelector('#notices-main');

  if(noticeDiv) {
    noticeDiv.style.opacity = '1.0';
    noticeDiv.style.transition = "opacity "+seconds+"s ease";

    let fade = 'false';
    let appSettings = document.getElementsByName('app-settings');
    if(appSettings.length > 0) {
      appSettings = JSON.parse(appSettings[0].dataset.settings);
      fade = appSettings.FLASH_NOTICE_FADEOUT ?? fade;
    }

    if(fade === 'true') {
      noticeDiv.style.opacity = '0';
    }

    setTimeout(() => {
      noticeDiv.style.opacity = '1';
      // noticeDiv.parentNode.removeChild(noticeDiv);
      noticeDiv.remove();
    }, seconds);
  }
}

/**
 * Display a message in the notices div.
 *
 * @param message
 * @param type
 * @param isSticky
 */
function displayNotice(message, type, isSticky = true) {
  let notice_class = 'notice-info';
  switch(type) {
    case 'warning':
      notice_class = 'notice-warning';
      break;
    case 'error':
      notice_class = 'notice-error'
      break;
    default:
      notice_class = 'notice-info';
      break;
  }

  let noticesDiv;
  noticesDiv = document.querySelector('.output-notices') ?? false;

  if(!noticesDiv) {
    noticesDiv = document.createElement('div');
    noticesDiv.id = 'notices-main';
    noticesDiv.classList.value = 'output-notices';

    let mainNav = document.querySelector('#main-nav');
    mainNav.insertAdjacentElement('afterend', noticesDiv);
  }

  let notice = noticesDiv.querySelector('.notice') ?? false;
  if(notice) {
    notice.querySelector('span').innerText = message;
    // remove classes
    notice.classList.remove(...notice.classList);
    notice.classList.add('callout', 'notice');
    if(isSticky) {
        notice.classList.add('not-fixed');
    }
    notice.classList.add(notice_class);
  } else {
    let div, button, span, span2;

    div = document.createElement('div');
    div.classList.add('callout', 'notice');

    if(isSticky) {
      div.classList.add('not-fixed');
    }

    div.classList.add(notice_class);
    div.setAttribute('data-closable', null);

    span = document.createElement('span');
    span.append(message);
    div.append(span);

    button = document.createElement('button');
    button.classList.add('close-button');
    button.setAttribute('aria-label', 'Dismiss alert');
    button.setAttribute('type', 'button');
    button.setAttribute('data-close', '');
    button.addEventListener('click', (notice) => { removeNotice(0); });

    span2 = document.createElement('span');
    span2.setAttribute('aria-hidden', 'true');
    span2.innerHTML = '&times;';

    button.append(span2);
    div.append(button);

    noticesDiv.append(div);
  }
}

export { closeNoticeHandler, getCookie, displayNotice, removeNotice };
