import {removeNotice, closeNoticeHandler} from "./functions";

let noticeElem = document.querySelector('#notices-main');

if(noticeElem) {
  noticeElem.querySelector('.close-button').addEventListener('click', closeNoticeHandler)
}

let delay = '5'; // in seconds
let appSettingsNodes = document.getElementsByName('app-settings');
if(appSettingsNodes.length > 0) {
  delay = parseInt(JSON.parse(appSettingsNodes[0].dataset.settings).FLASH_NOTICE_DURATION); // in seconds
}

removeNotice(delay);
